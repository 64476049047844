<template>
    <v-app>
        <v-main>
            <v-app-bar color="orange">
                <v-toolbar-title style="color: white">{{cafe.address}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn small color="gray"
                       @click="refreshOrders()">
                    Заказы: {{ orders.length }}
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn small color="gray"
                       @click="loadAllOrders()">
                    Все заказы
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn small
                       class="white--text"
                       v-bind:color="cafe.isActive ? 'green darken-1' : 'red darken-2'"
                       @click="cafeActivityDialog = true">
                    Приём заказов
                </v-btn>

                <v-spacer></v-spacer>

                <v-menu bottom left>
                    <template v-slot:activator="{ on }">
                        <v-btn
                                dark
                                icon
                                v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item @click="notificationSound.play()">
                            <v-list-item-title>Проверка звука</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="logout()">
                            <v-list-item-title>Выход</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-app-bar>
            <v-container
                    fill-height
                    style="background-color: lightgrey"
                    fluid>
                <loading :active.sync="isLoading"
                         :can-cancel="false"
                         :is-full-page="true"></loading>

                <v-card v-if="orders.length === 0"
                        class="mx-auto">
                    <v-list-item three-line>
                        <v-list-item-content>
                            <div class="overline mb-4">Новых заказов нет</div>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>

                <v-row v-if="!isLoading && orders.length !== 0" justify="center" align="center" v-for="order in orders"
                       v-bind:key="order.id">
                    <v-col cols="12" lg="8">
                        <v-card
                                class="mx-auto">
                            <v-card-text>
                                <h2>
                                    Заказ №{{order.orderNumber}}
                                    <v-chip v-if="order.orderStatus === 1"
                                            class="ma-2"
                                            color="light-blue"
                                            text-color="white">
                                        <div class="font-weight-light">Новый</div>
                                    </v-chip>
                                    <v-chip v-if="order.orderStatus === 2"
                                            class="ma-2"
                                            color="orange darken-3"
                                            text-color="white">
                                        <div class="font-weight-light">В работе</div>
                                    </v-chip>
                                    <v-chip v-if="order.orderStatus === 3"
                                            class="ma-2"
                                            color="deep-purple lighten-1"
                                            text-color="white">
                                        <div class="font-weight-light">Не забран</div>
                                    </v-chip>
                                    <v-chip v-if="order.orderStatus === 4"
                                            class="ma-2"
                                            color="green"
                                            text-color="white">
                                        <div class="font-weight-light">Завершён</div>
                                    </v-chip>
                                    <v-chip v-if="order.orderStatus === 5"
                                            class="ma-2"
                                            color="red"
                                            text-color="white">
                                        <div class="font-weight-light">Отменён</div>
                                    </v-chip>
                                    <v-chip v-if="order.orderStatus === 6"
                                            class="ma-2"
                                            color="green"
                                            text-color="white">
                                        <div class="font-weight-light">Готов к выдаче</div>
                                    </v-chip>
                                </h2>
                                <div class="black--text mb-2">
                                    <v-icon>mdi-account</v-icon>
                                    {{order.client.fullName}}
                                    <v-icon>mdi-cellphone</v-icon>
                                    +7{{order.client.phoneNumber}}
                                </div>

                                <div class="text-md-body-1 black--text">
                                    <v-chip
                                            class="ma-2"
                                            color="orange"
                                            text-color="white">
                                        <div class="font-weight-light">Создан в {{order.createdAtStr}}</div>
                                    </v-chip>

                                    <v-chip
                                            class="ma-2"
                                            color="red"
                                            text-color="white">
                                        <div class="font-weight-light">Приготовить до {{order.orderTimeStr}}</div>
                                    </v-chip>
                                </div>
                                <div class="text-h6">Состав заказа</div>
                                <v-simple-table fixed-header>
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th class="text-md-body-1 font-weight-bold text-left">Наименование</th>
                                            <th class="text-md-body-1 font-weight-bold text-left">Цена, р.</th>
                                            <th class="text-md-body-1 font-weight-bold text-left">Кол-во, шт.</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="orderItem in order.orderContent" :key="orderItem.item_id">
                                            <td class="text-md-body-1">{{ getMenuItem(orderItem.item_id) }}</td>
                                            <td class="text-md-body-1 align-content-end">{{ orderItem.price }}</td>
                                            <td class="text-md-body-1 align-content-end">{{orderItem.qnt}}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-md-body-1 font-weight-bold">Итого</td>
                                            <td colspan="2"
                                                class="text-md-body-1 align-content-end  font-weight-bold">
                                                {{totalSum(order)}}
                                            </td>

                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>
                            <v-card-actions>
                                <div v-if="order.orderStatus === 1">
                                    <v-btn class="order-action-button white--text"
                                           large
                                           color="orange"
                                           @click="updateOrder( {orderId: order.orderId, newOrderStatus:2})">
                                        Взять в работу
                                    </v-btn>
                                </div>

                                <div v-if="order.orderStatus === 2 || order.orderStatus === 6">
                                      <v-btn v-if="order.orderStatus === 2" class="order-action-button white--text"
                                             large
                                             color="orange"
                                             @click="updateOrder( {orderId: order.orderId, newOrderStatus:6})">
                                          Готов к выдаче
                                      </v-btn>
                                    <v-btn class="order-action-button white--text"
                                           large
                                           color="green"
                                           @click="updateOrder( {orderId: order.orderId, newOrderStatus:4})">
                                        Завершён
                                    </v-btn>
                                    <v-btn class="order-action-button white--text"
                                           large
                                           color="red"
                                           @click="updateOrder( {orderId: order.orderId, newOrderStatus:3})">
                                        Не забран клиентом
                                    </v-btn>
                                </div>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-dialog v-model="cafeActivityDialog" max-width="400">
                        <v-card>
                            <v-card-title class="headline">Приём заказов</v-card-title>
                            <v-card-text>
                                <div class="text-h6">
                                    Приём заказов <span v-if="cafe.isActive" class="green--text">включён</span><span
                                        v-if="!cafe.isActive" class="red--text">
                                    выключен</span>
                                </div>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                        color="red darken-1"
                                        :disabled="!cafe.isActive"
                                        text
                                        @click="updateCafe(false)">
                                    Выключить
                                </v-btn>
                                <v-btn color="green darken-1"
                                       :disabled="cafe.isActive"
                                       text
                                       @click="updateCafe(true)">
                                    Включить
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>

            </v-container>
            <!--<v-bottom-navigation
                    :value="0"
                    color="orange">
                <v-btn value="orders">
                    <span>Заказы</span>
                    <v-icon>mdi-history</v-icon>
                </v-btn>
                <v-btn value="isActive"
                       @click="cafeActivityDialog = true">
                    <span>Приём заказов</span>
                    <v-icon>mdi-clipboard-edit-outline</v-icon>
                </v-btn>
            </v-bottom-navigation>-->
            <v-snackbar
                    v-model="showErrorSnackbar"
                    :multi-line="true"
                    absolute
                    bottom>
                {{ errorMessage }}
                <template>
                    <v-btn
                            color="red"
                            text
                            @click="showErrorSnackbar = false">
                        Хорошо
                    </v-btn>
                </template>
            </v-snackbar>
            <v-snackbar
                    v-model="showNewOrderSnackbar"
                    :multi-line="false"
                    absolute
                    top>
                Появился новый заказ
                <template>
                    <v-btn
                            color="red"
                            text
                            @click="showNewOrderSnackbar = false">
                        Хорошо
                    </v-btn>
                </template>
            </v-snackbar>
        </v-main>
    </v-app>

</template>

<style scoped>
    .order-action-button {
        margin-right: 1em;
        margin-bottom: 1em;
        margin-left: 1em;
    }
</style>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css'

    export default {
        name: "OrderQueue",
        data: () => ({
            lodash: require('lodash'),
            notificationSound: new Audio('./Ripples.mp3'),
            isLoading: true,
            cafeActivityDialog: false,
            showNewOrderSnackbar: false,
            showErrorSnackbar: false,
            errorMessage: '',
            cafe: {
                address: '',
                isActive: false
            },
            orders: [],
            isOrderQueueProcessing: false,
            notificationAudioId: 1
        }),
        computed: mapGetters([
            "getJwtToken",
            "getAuthCredentials",
            'getPreviousOrdersAmount',
            "isLoggedIn",
            "getMenuItem",
            'getIsMenuLoaded'
        ]),
        mounted() {
            if (!this.isLoggedIn) {
                this.$router.push('/login')
                return
            }

            this.loadQueue().then(() => {
                setInterval(() => {
                    if (this.isOrderQueueProcessing) {
                        return
                    }

                    if (!this.cafe.isActive) {
                        return
                    }

                    this.refreshOrders()
                }, 35000)
            })
        },
        methods: {
            ...mapActions([
                'webAuth',
                'refreshQueue',
                'loadAllDailyQueue',
                'loadMenu',
                'updateOrderStatus',
                'logoutOperator',
                'updateCafeActivity'
            ]),
            async loadQueue() {
                if (!this.getIsMenuLoaded) {
                    await this.loadMenu()
                }

                await this.refreshOrders()

                this.isLoading = false
            },
            async loadAllOrders(){
                this.isOrderQueueProcessing = true

                const response = await this.loadAllDailyQueue(this.getJwtToken)
                if (!response.result) {
                    if (response.code === 401) {
                        const credentials = this.getAuthCredentials
                        this.webAuth(credentials)
                            .then(authResp => {
                                if (authResp.result) {
                                    this.$router.go()
                                } else {
                                    this.$router.push('/login')
                                }
                            })
                        return
                    }

                    this.errorMessage = response.error
                    this.showErrorSnackbar = true
                    return
                }

                this.cafe = response.data.cafe

                const hasNewOrders = !this.lodash.isEqual(this.orders, response.data.orders)
                    // (this.orders.length !== response.data.orders.length)
                    && (response.data.orders.length !== 0)
                    && this.cafe.isActive

                if (hasNewOrders) {
                    this.orders = []
                    this.orders = response.data.orders
                }

                this.showNewOrderSnackbar = hasNewOrders

                this.isOrderQueueProcessing = false
            },
            async refreshOrders() {
                this.isOrderQueueProcessing = true

                const response = await this.refreshQueue(this.getJwtToken)
                if (!response.result) {
                    if (response.code === 401) {
                        const credentials = this.getAuthCredentials
                        this.webAuth(credentials)
                            .then(authResp => {
                                if (authResp.result) {
                                    this.$router.go()
                                } else {
                                    this.$router.push('/login')
                                }
                            })
                        return
                    }

                    this.errorMessage = response.error
                    this.showErrorSnackbar = true
                    return
                }

                this.cafe = response.data.cafe

                const hasNewOrders = !this.lodash.isEqual(this.orders, response.data.orders)
                    // (this.orders.length !== response.data.orders.length)
                    && (response.data.orders.length !== 0)
                    && this.cafe.isActive

                if (hasNewOrders) {
                    this.orders = []
                    this.notificationSound.muted = false
                    this.notificationSound.play()
                    this.orders = response.data.orders
                }

                this.showNewOrderSnackbar = hasNewOrders

                this.isOrderQueueProcessing = false
            },
            async updateOrder(order) {
                this.isLoading = true
                this.isOrderQueueProcessing = true

                this.updateOrderStatus({order: order, jwtToken: this.getJwtToken}).then(response => {
                    this.isOrderQueueProcessing = false

                    if (!response.result) {
                        this.errorMessage = response.error
                        this.showErrorSnackbar = true
                        this.isLoading = false
                        return
                    }

                    this.isOrderQueueProcessing = true

                    this.refreshOrders().then(() => {
                        this.isLoading = false
                        this.isOrderQueueProcessing = false
                    })
                })
            },
            async updateCafe(isActive) {
                this.isLoading = true
                this.updateCafeActivity({cafeActivity: isActive, jwtToken: this.getJwtToken}).then(response => {
                    this.isLoading = false

                    this.cafeActivityDialog = false

                    if (!response.result) {
                        this.errorMessage = response.error
                        this.showErrorSnackbar = true
                        this.isLoading = false
                        return
                    }

                    this.cafe.isActive = !this.cafe.isActive

                    if (this.cafe.isActive) {
                        this.errorMessage = "Приём заказов включён"
                    } else {
                        this.errorMessage = "Приём заказов выключен"
                        this.orders = []
                    }

                    this.showErrorSnackbar = true
                })
            },
            totalSum: order => {
                return order.orderContent.reduce(function (total, contentItem) {
                    return total + (contentItem.price * contentItem.qnt);
                }, 0);
            },
            playNewOrderSound: () => {

            },
            logout() {
                this.logoutOperator()
                this.$router.push('/login')
            }
        },
        components: {
            Loading
        }
    }
</script>